import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { toast } from "react-toastify";
import { backendApis } from "../../config";

function Department({ saveDepartment, email, next, width }) {
  let fexspaceBaseUrl = process.env.REACT_APP_FEXSPACE_CLOUD_DOMAIN;
  const { backendUrl, token, user } = useAuth();

  const nameRef = useRef(null);
  const descRef = useRef(null);
  const checkRef = useRef(null);

  const createDepartment = (cond) => {
    const name = nameRef.current.value;
    const description = descRef.current.value;
    const create_folder = checkRef.current.checked;
    if (name) {
      let newDepartment = {
        Dept_ID: name,
        Dept_Name: name,
        Dept_Desc: description,
        users: [],
      };
      let saveDepartment = async () => {
        let url = `${backendApis[0].url}/api/company/save-system-departments`;
        try {
          let res = await fetch(url, {
            method: "POST",
            credentials: "include",
            headers: {
              Accept: "Application/json",
              "Content-Type": "Application/json",
              ...(token ? { Authorization: `Bearer ${token}` } : {}),
            },
            body: JSON.stringify(newDepartment),
          });

          if (!res.ok) {
            // toast.success("Done");
            throw new Error("Error creating department");
            // create in fexspace
          }
          let fexUrl = `${fexspaceBaseUrl}/create-invex-department`;
          res = await fetch(fexUrl, {
            method: "POST",
            credentials: "include",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ name, email, create_folder }),
          });
          console.log(res);
          if (res.ok) {
            toast.success("Done");
            if (!cond) {
              // next page if "save and next" is clicked but not "Add department"
              next();
            }
          }
        } catch (error) {
          toast.error("Error occurred, pleas try again");
        }
      };
      saveDepartment();
      nameRef.current.value = "";
      descRef.current.value = "";
    } else {
      console.log(email);
      toast.warning("Please enter a department name");
    }
  };
  useEffect(() => {
    saveDepartment(createDepartment);
  }, []);
  return (
    <div className="contents departments" style={{ width }}>
      <div className="heading">
        <h1>What are the departments in your company</h1>
        <h5 className="desc">
          Create as much departments staffs can belong to
        </h5>
      </div>
      <div className="dept-container">
        {/* <div className="created-departments">
          <h3>Created Departments</h3>
          <ul>
            {departments.map((department, index) => (
              <li key={index} className="mb-3 ">
                <div className="d-flex gap-2">
                  <span className="">
                    <h5>{department.name}</h5>
                    <p>{department.description}</p>
                  </span>
                  <button
                    id="delete-btn"
                    onClick={() =>
                      setDepartments((prev) =>
                        prev.filter((_, i) => i !== index)
                      )
                    }
                  >
                    X
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div> */}
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className="form-group mb-3">
            <label htmlFor="department">Department Name</label>
            <input
              ref={nameRef}
              type="text"
              className="form-control"
              id="department"
              placeholder="Enter unique department name"
            />
            <label htmlFor="description">Department Description</label>
            <textarea
              ref={descRef}
              type="text"
              className="form-control"
              id="description"
              placeholder="Enter department description"
              rows={4}
            />
            <div className="checkbox">
              <input ref={checkRef} type="checkbox" id="fexspace" />
              <label htmlFor="fexspace">Create group folder in fexspace</label>
            </div>
          </div>
          <button
            type="submit"
            className="outline navigation"
            onClick={() => createDepartment(true)}
          >
            Add Department
          </button>
        </form>
      </div>
    </div>
  );
}

export default Department;
