import { useFormik } from "formik";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import DateTime from "react-datetime";
import FormBuilderModal from "./FormBuilderModal";
import { useState, useMemo, Fragment } from "react";
import moment from "moment";
import { useAuth } from "../../hooks/useAuth";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import ModalLoader from "../utils/ModalLoader";
import { formDataForFormBuider, formTypes, pageSizes } from "../../utils/helpers";
import { isEmpty, uniqBy } from "lodash";
import { queryActions } from "../../utils/reactQueryActions";
import ConfirmDialog from "../ConfirmDialogue";
import { useEffectOnce } from "../../utils/hooks";

export default function NewRequisitionFormBuilder({
  showNewRequisitionModal,
  setShowNewRequisitionModal,
  refetch,
  type,
  selectedForm,
  setSelectedForm,
}) {
  const queryClient = useQueryClient();
  const { backendUrl, token, user: authUser } = useAuth();
  const [file, setFile] = useState();
  const [showFormBuilderModal, setShowFormBuilderModal] = useState(false);

  const setup = async () => {
    let response = await fetch(`${backendUrl}/api/forms/set-up`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    data.formCategory = data.formCategory.map(el => ({
      label: el.category,
      value: el.category,
    }));

    /* data.formType = data.formType.map((el) => ({
      label: el.type,
      value: el.type,
    })); */

    return data;
  };

  const {
    data: setUpData,
    // refetch,
  } = useQuery(["FORM_SETUP"], () => setup(), {
    keepPreviousData: true,
  });

  //----------------------------------------------------------------------
  // fetch data for form
  const getDepartmentsForForm = async () => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/users/departments-with-users`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };

  const { data = { departments: [], staffs: [], jobGrades: [] }, isFetching } = useQuery(
    ["GET_USERS_WITH_DEPARTMENTS"],
    () => getDepartmentsForForm(),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );
  const createTemplate = async payload => {
    const formData = formDataForFormBuider(payload);

    let url =
      type === "Template"
        ? `${backendUrl}/api/forms/create`
        : `${backendUrl}/api/forms/create-form`;
    let response = await fetch(url, {
      method: "POST",
      credentials: "include",
      body: formData,
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const createMutation = useMutation(payload => createTemplate(payload), {
    onSuccess: ({ message }) => {
      toast.success(message);
      formik.resetForm();
      refetch();

      setShowFormBuilderModal(false);
      setShowNewRequisitionModal(false);
    },
    onError: ({ message = "" }) => {
      toast.error(`Unable to perform action: ${message}`);
    },
  });

  const formik = useFormik({
    initialValues: {
      Title: "",
      Date_Log: moment(),
      Type: "Organisation",
      Category: "",
      Template: "",
      Draft: false,
      // Signatories: [],
      approvalType: "Required",
      department: "",
      jobGrade: "",
      staff: "",
      enabled: true,
      formStartNumber: "",
      formEndNumber: "",
      formNumberPrefix: "",
      revision: 0,
      orientation: "Potrait",
      pageSize: "A4",
      register: false,
      numberType: "Increment",
      documentNumber: "",
      isMultiStep: false,
    },
    onSubmit: values => {
      createMutation.mutate({ ...values });
    },
  });

  useEffectOnce(() => {
    if (!isEmpty(selectedForm)) {
      formik.setValues({
        ...formik.values,
        Template: JSON.parse(selectedForm.Template),
        Type: selectedForm.Type,
        Category: selectedForm.Category,
        approvalType: selectedForm.approvalType,
        department: selectedForm.department,
        jobGrade: selectedForm.jobGrade,
        staff: selectedForm.staff,
      });
    }
  });

  //
  const saveForm = async ({ data, isDraft, isMultiStep }) => {
    formik.setFieldValue("Template", data);
    formik.setFieldValue("Draft", isDraft);
    formik.setFieldValue("isMultiStep", isMultiStep);

    if (
      await ConfirmDialog({
        title: "Submit",
        description: "Are you sure, you want to submit",
      })
    ) {
      formik.submitForm();
    }
  };

  const handleCreateType = newType => {
    queryClient.setQueryData(["FORM_SETUP"], data => {
      data.formType = [...data.formType, { label: newType, value: newType }];
      return data;
    });
    formik.setFieldValue("Type", newType);
  };

  const handleCreateCategory = newCategory => {
    queryClient.setQueryData(["FORM_SETUP"], data => {
      data.formCategory = [...data.formCategory, { label: newCategory, value: newCategory }];
      return data;
    });
    formik.setFieldValue("Category", newCategory);
  };

  const handleFileInput = e => {
    e.preventDefault();
    let fileInput = e.target;
    setFile(fileInput.files[0]);
  };

  const staffSelectOptions = useMemo(() => {
    const jobGrades = data.staffs
      .filter(el => el.Department === formik.values.department)
      .map(el => ({ jobGrade: el.jobGrade }));

    const staffs = data.staffs.filter(
      el => el.Department === formik.values.department && el.jobGrade === formik.values.jobGrade
    );

    return {
      departments: [
        { label: "Any Department", value: "" },
        { label: "Department Lead", value: "Department Lead" },
        ...data.departments.map(el => ({
          label: el.Department,
          value: el.Department,
        })),
      ],
      jobGrades: [
        { label: "Any Job Grade", value: "" },
        ...uniqBy(jobGrades, "jobGrade").map(el => ({
          label: el.jobGrade,
          value: el.jobGrade,
        })),
      ],
      staffs: [
        { label: "Any Staff", value: "" },
        ...staffs.map(el => ({
          label: `${el?.changeableId ? el?.changeableId : el.Staff_ID} - ${el.Name}`,
          value: el.Staff_ID,
        })),
      ],
    };
  }, [data, formik.values.department, formik.values.jobGrade]);

  return (
    <>
      <Modal
        show={showNewRequisitionModal}
        backdrop="static"
        animation={false}
        onHide={() => {
          setShowNewRequisitionModal(false);
          if (setSelectedForm) setSelectedForm(null);
        }}
      >
        <Modal.Header closeButton>
          <div>
            <h1 className="h5">Create New {type}</h1>{" "}
            <p>Build your forms correctly and as needed</p>
          </div>
        </Modal.Header>

        <Modal.Body>
          <Form noValidate onSubmit={formik.handleSubmit}>
            {type !== "Template" && (
              <>
                <Form.Group className=" mb-3 pb-2">
                  <Form.Label className="mb-1">Document Number</Form.Label>
                  <Form.Control
                    className=""
                    type="text"
                    placeholder="Enter Document Number"
                    name="documentNumber"
                    value={formik.values.documentNumber}
                    onChange={formik.handleChange}
                    isInvalid={formik.touched.documentNumber && !!formik.errors.documentNumber}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.documentNumber}
                  </Form.Control.Feedback>
                </Form.Group>

                <div className="d-flex gap-2">
                  <Form.Group className=" mb-3 pb-2">
                    <Form.Label className="mb-1">Form Number</Form.Label>
                    <Form.Control
                      className=""
                      type="text"
                      placeholder="eg. AB-BC-CD"
                      name="formNumberPrefix"
                      value={formik.values.formNumberPrefix}
                      onChange={formik.handleChange}
                      isInvalid={
                        formik.touched.formNumberPrefix && !!formik.errors.formNumberPrefix
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.formNumberPrefix}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className=" mb-3 pb-2">
                    <Form.Label className="mb-1">Start Number</Form.Label>
                    <Form.Control
                      className=""
                      type="number"
                      placeholder="Enter Start Number"
                      name="formStartNumber"
                      value={formik.values.formStartNumber}
                      onChange={formik.handleChange}
                      isInvalid={formik.touched.formStartNumber && !!formik.errors.formStartNumber}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.formStartNumber}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className=" mb-3 pb-2">
                    <Form.Label className="mb-1">End Number</Form.Label>
                    <Form.Control
                      className=""
                      type="number"
                      placeholder="Enter Start Number"
                      name="formEndNumber"
                      value={formik.values.formEndNumber}
                      onChange={formik.handleChange}
                      isInvalid={formik.touched.formEndNumber && !!formik.errors.formEndNumber}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.formEndNumber}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>

                <Form.Group className="mb-3">
                  <Form.Label>Form Number Type</Form.Label>

                  <div>
                    <Form.Check
                      inline
                      label="Increment"
                      value="Increment"
                      name="numberType"
                      type={"radio"}
                      id="Increment"
                      checked={formik.values.numberType === "Increment"}
                      onChange={formik.handleChange}
                    />
                    <Form.Check
                      inline
                      label="Fixed"
                      value="Fixed"
                      name="numberType"
                      type={"radio"}
                      id="Fixed"
                      checked={formik.values.numberType === "Fixed"}
                      onChange={formik.handleChange}
                    />
                  </div>
                </Form.Group>
              </>
            )}

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Title</Form.Label>
              <Form.Control
                className=""
                type="text"
                placeholder="Enter Title"
                name="Title"
                value={formik.values.Title}
                onChange={formik.handleChange}
                isInvalid={formik.touched.Title && !!formik.errors.Title}
              />
              <Form.Control.Feedback type="invalid">{formik.errors.Title}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Type</Form.Label>
              <CreatableSelect
                classNamePrefix={`form-select`}
                options={formTypes}
                value={formTypes.find(el => el.value === formik.values.Type)}
                onChange={({ value }) => {
                  formik.setFieldValue("Type", value);
                  if (value === "Public") {
                    formik.setFieldValue("approvalType", "Not-Required");
                  }
                }}
                isSearchable
                onCreateOption={handleCreateType}
              />
              <Form.Control.Feedback type="invalid" />
            </Form.Group>

            {formik.values.Type === "Organisation" && (
              <>
                <Form.Group className="mb-3">
                  <Form.Label>Department </Form.Label>
                  <Select
                    classNamePrefix={`form-select`}
                    name="department"
                    options={staffSelectOptions?.departments}
                    value={staffSelectOptions?.departments.find(
                      el => el.value === formik.values.department
                    )}
                    onChange={({ value }) => formik.setFieldValue("department", value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Job Grade </Form.Label>

                  <Select
                    classNamePrefix={`form-select`}
                    name="jobGrade"
                    options={staffSelectOptions?.jobGrades}
                    value={staffSelectOptions?.jobGrades.find(
                      el => el.value === formik.values.jobGrade
                    )}
                    onChange={({ value }) => formik.setFieldValue("jobGrade", value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Staff </Form.Label>
                  <Select
                    classNamePrefix={`form-select`}
                    name="staff"
                    options={staffSelectOptions?.staffs}
                    value={staffSelectOptions?.staffs.find(el => el.value === formik.values.staff)}
                    onChange={({ value }) => formik.setFieldValue("staff", value)}
                  />
                </Form.Group>
              </>
            )}

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Category</Form.Label>
              <CreatableSelect
                classNamePrefix={`form-select`}
                options={setUpData?.formCategory}
                value={setUpData?.formCategory.find(el => el.value === formik.values.Category)}
                onChange={({ value }) => formik.setFieldValue("Category", value)}
                isSearchable
                onCreateOption={handleCreateCategory}
              />
              <Form.Control.Feedback type="invalid" />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Has Approval</Form.Label>

              <div>
                <Form.Check
                  inline
                  label="Approval Required"
                  value="Required"
                  name="approvalType"
                  type={"radio"}
                  id="Approval Required"
                  checked={formik.values.approvalType === "Required"}
                  onChange={formik.handleChange}
                />
                <Form.Check
                  inline
                  label="Fill form only (No Approval Required)"
                  value="Not-Required"
                  name="approvalType"
                  type={"radio"}
                  id="Fill form only"
                  checked={formik.values.approvalType === "Not-Required"}
                  onChange={formik.handleChange}
                />

                <Form.Check
                  inline
                  label="Fill form and Send for Approval"
                  value="Fill and Approval Required"
                  name="approvalType"
                  type={"radio"}
                  id="Fill form only"
                  checked={formik.values.approvalType === "Fill and Approval Required"}
                  onChange={formik.handleChange}
                />
              </div>
            </Form.Group>

            <Form.Group className="mb-3 pb-2">
              <Form.Label className="mb-1">Date</Form.Label>
              <DateTime
                timeFormat={false}
                closeOnSelect={true}
                closeOnClickOutside={true}
                dateFormat="MMM DD, YYYY"
                inputProps={{
                  className: `date-input form-control`,
                  placeholder: "Select date",
                  readOnly: true,
                }}
                value={formik.values.Date_Log}
                onChange={date => {
                  formik.setFieldValue("Date_Log", date, true);
                }}
              />
            </Form.Group>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="d-flex gap-3 fw-6">
                <Form.Switch
                  label="Enabled "
                  checked={formik.values.enabled}
                  onChange={() => formik.setFieldValue("enabled", !formik.values.enabled)}
                />
              </Form.Label>
            </Form.Group>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Revision</Form.Label>
              <Form.Control
                className=""
                type="number"
                placeholder="Enter Revision"
                name="revision"
                value={formik.values.revision}
                onChange={formik.handleChange}
                isInvalid={formik.touched.revision && !!formik.errors.revision}
              />
              <Form.Control.Feedback type="invalid">{formik.errors.revision}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Orientation</Form.Label>

              <div>
                <Form.Check
                  inline
                  label="Potrait"
                  value="Potrait"
                  name="orientation"
                  type={"radio"}
                  id="Potrait"
                  checked={formik.values.orientation === "Potrait"}
                  onChange={formik.handleChange}
                />
                <Form.Check
                  inline
                  label="Landscape"
                  value="Landscape"
                  name="orientation"
                  type={"radio"}
                  id="Landscape"
                  checked={formik.values.orientation === "Landscape"}
                  onChange={formik.handleChange}
                />
              </div>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Page Size</Form.Label>
              <Select
                classNamePrefix={"form-select"}
                options={pageSizes}
                value={pageSizes.find(el => el.value === formik.values.pageSize)}
                onChange={({ value }) => formik.setFieldValue("pageSize", value)}
              />
            </Form.Group>

            <Row>
              <Col>
                <Form.Group className=" mb-3 pb-2">
                  <Form.Label className="d-flex gap-3 fw-6">
                    <Form.Switch
                      label="Register Form"
                      checked={formik.values.register}
                      onChange={() => formik.setFieldValue("register", !formik.values.register)}
                    />
                  </Form.Label>
                </Form.Group>
              </Col>

              <Col>
                <Form.Group className=" mb-3 pb-2">
                  <Form.Label className="d-flex gap-3 fw-6">
                    <Form.Switch
                      label="Make a multi-step form"
                      checked={formik.values.isMultiStep}
                      onChange={() =>
                        formik.setFieldValue("isMultiStep", !formik.values.isMultiStep)
                      }
                    />
                  </Form.Label>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button
            onClick={() => {
              if (isEmpty(formik.values.formNumberPrefix) && type !== "Template") {
                toast.error("Enter Form Number");
                return formik.setFieldError("formNumberPrefix", "Enter Form Number");
              }

              return setShowFormBuilderModal(true);
            }}
            className="px-4"
            // variant="outline-primary"
          >
            Create Form
          </Button>

          {/*  <Button onClick={() => formik.submitForm()} className="px-4">
            Post
          </Button> */}
        </Modal.Footer>
      </Modal>

      {showFormBuilderModal && (
        <FormBuilderModal
          showFormBuilderModal={showFormBuilderModal}
          setShowFormBuilderModal={setShowFormBuilderModal}
          saveForm={saveForm}
          template={selectedForm?.Template}
          isMultiStep={formik.values.isMultiStep}
        />
      )}

      <ModalLoader show={createMutation.isLoading} />
    </>
  );
}
