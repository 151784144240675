import { isEmpty, upperFirst } from "lodash";
import React, { useState, useEffect, useMemo } from "react";
import noImage from "../../assets/images/profile.jpg";

import "./../../assets/scss/scoped/avatar.scoped.scss";
import { useAuth } from "../../hooks/useAuth";
import { useBackendUrl, useCustomerBackendUrl, useLocalStorage } from "../../utils/hooks";
import { useQuery } from "react-query";

export default React.memo(function Avatar({
  name,
  company = "",
  department = "",
  onFileUpload,
  nameInput,
  userId,
  image,
  extralText,
  vendor,
  rounded,
  ...props
}) {
  const { user: authUser, authVendor, customerBackendUrl } = useAuth();
  const backendUrl = useBackendUrl();

  // Calculate finalUserId only once
  const finalUserId = useMemo(
    () => (vendor ? authVendor?.Vendor_ID : userId ? userId : authUser?.Staff_ID),
    [vendor, authVendor?.Vendor_ID, userId, authUser?.Staff_ID]
  );

  // Function to fetch profile picture
  const fetchProfilePic = async () => {
    if (!finalUserId) {
      throw new Error("No user ID available");
    }

    let endpoint;
    if (vendor) {
      endpoint = `${backendUrl}/api/vendors/profile-pics/${finalUserId}`;
    } else {
      endpoint = `${
        !isEmpty(props.Cust_ID) ? customerBackendUrl : backendUrl
      }/api/users/profile-pics/${finalUserId}`;
    }

    const response = await fetch(endpoint);

    if (!response.ok) {
      throw new Error("Failed to fetch profile picture");
    }

    return response.blob();
  };

  // Use React Query to fetch and cache the profile picture
  const { data: profilePic, isError } = useQuery(
    ["profilePic", finalUserId, vendor, props.Cust_ID, authUser?.timestamp],
    fetchProfilePic,
    {
      staleTime: 5 * 60 * 1000, // 5 minutes
      cacheTime: 30 * 60 * 1000, // 30 minutes
      enabled: !!finalUserId && !image, // Only run query if we have a user ID and no direct image prop
      retry: 1, // Only retry once
    }
  );

  // Use the provided image or the fetched profile pic
  const effectiveImage = useMemo(() => image || profilePic, [image, profilePic]);

  // Memoize image source processing
  const imgSrc = useMemo(() => {
    if (!effectiveImage) return null;
    return effectiveImage?.type?.split("/")[0] === "image"
      ? URL.createObjectURL(effectiveImage)
      : noImage;
  }, [effectiveImage]);

  return (
    <div className={`${props?.notcircle ? "rounded" : "rounded-circle"} avatar`} {...props}>
      {!effectiveImage ? (
        <div
          className="d-flex align-items-center justify-content-center w-100 h-100"
          title={
            extralText ? extralText : `${name} - ${department} ${company ? `(${company})` : ""}`
          }
        >
          <label className="d-flex align-items-center justify-content-center p-cursor w-100 h-100 m-0">
            {onFileUpload && (
              <input type="file" onChange={onFileUpload} name={nameInput} className="d-none" />
            )}
            {extralText ? (
              <small className="text-center fw-bold">{extralText}</small>
            ) : (
              <div className="fw-bold fs-6" style={{ lineHeight: 0 }}>
                {upperFirst(name ? name[0] : "...")}
              </div>
            )}
          </label>
        </div>
      ) : (
        <label className="text-center d-flex p-cursor w-100 h-100 m-0">
          {onFileUpload && (
            <input type="file" onChange={onFileUpload} name={nameInput} className="d-none" />
          )}
          <img
            src={imgSrc}
            alt="pics"
            title={authUser?.timestamp}
            className={`w-100 h-100 img-fluid rounded ${rounded ? "rounded-circle" : ""}`}
            style={{ objectFit: "cover" }}
          />
        </label>
      )}
    </div>
  );
});
