import { Dropdown, Table, Form, Button, InputGroup, Image, Modal } from "react-bootstrap";
import { CSVLink } from "react-csv";
import DetailedIcon, {
  ExportIcon,
  FilterIcon,
  FilterTwoIcon,
  BusinessBillIcon,
  PrintIcon,
  SummaryChartIconUp,
  SummaryChartIconDown,
  SummaryCloseIcon,
  FilterCollapseIcon,
  RecieptIcon,
  PageHeaderListIcon,
  BookIcon,
  ExcelIcon,
  PDFIcon,
  NoSelectedItemIcon,
  DeleteIcon,
  LinkVariantIcon,
  ReoccurringInvoiceIcon,
  StandardInvoiceIcon,
  ClockIcon,
  EditIcon,
} from "../Icons";
import "../../assets/scss/reports/cashbook.scss";
import "../../assets/scss/dashboard.scss";
import { useEffect, useRef, useState } from "react";
import CachedIcon from "mdi-react/CachedIcon";
import Select from "react-select";
import useDebounce, {
  useCurrencies,
  useIsAdmin,
  useIsCashier,
  useLocalStorage,
  useQueryParams,
  useScrollTop,
} from "../../utils/hooks";
import { appSettings, services } from "../../config";
import { reportActions } from "../../utils/reactQueryActions";
import { useQuery, useMutation } from "react-query";
import currency from "currency.js";
import { endOfDay, format, parse, startOfDay } from "date-fns";
import ReactPaginate from "react-paginate";
import {
  copyText,
  customerFullName,
  defaultSelectValue,
  fetchActionsUtil,
  formatDate,
  formatNumberSystem,
  paginationOptions,
  reactSelectTheme,
} from "../../utils/helpers";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import queryString from "query-string";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import { useAuth } from "../../hooks/useAuth";
import { Link, useNavigate } from "react-router-dom";
import CustomerSelector from "../utils/CustomerSelector";
import { Popover } from "react-tiny-popover";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { lowerCase, truncate } from "lodash";
import { isEmpty } from "lodash";
import NoTableItem from "../utils/NoTableItem";
import { useDownloadExcel } from "../../hooks/useDownloadExcel";
import ModalLoader from "../utils/ModalLoader";
import TableComponent from "../TableComponent";
import TransactionsTable from "../utils/TransactionsTable";
import { currencies } from "../../utils/currencies";
import { IsPrivileged } from "../DisplayChildElement";
import PrivilegedAction from "../PrivilegedAction";
import { toast } from "react-toastify";
import * as yup from "yup";
import ConfirmDialog from "../ConfirmDialogue";
import { read, utils } from "xlsx";
import InvoiceLayout from "./InvoiceWrapper";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import ArrowRightBoldCircleOutlineIcon from "mdi-react/ArrowRightBoldCircleOutlineIcon";
import EyeOutlineIcon from "mdi-react/EyeOutlineIcon";
import CustomInvoiceTableComponent from "../CustomInvoiceTableComponent";
import InvoiceAnalysisChart from "./InvoiceAnalysisChart";
import InvoiceAnalysisPieChart from "./InvoiceAnalysisPieChart";
import CustomerAvatar from "../utils/CustomerAvatar";
import InvoiceInnerPageLayout from "../InvioiceInnerPageLayout";
import { description } from "platform";
/* const currenciesOptions = [
    {
      label: "All Currencies",
      value: "",
    },
    ...currencies.map((el) => ({
      ...el,
      label: `${el.name} (${el.symbol})`,
      value: el.cc,
    })),
  ]; */

function LogModal({ logData, close, open }) {
  const tableHead = () => {
    return (
      <thead>
        <tr>
          <th>S/N</th>
          <th>Date</th>
          <th>Status</th>
          <th>Message</th>
        </tr>
      </thead>
    );
  };

  const displayTableData = (el, index) => (
    <>
      <td>{index + 1}</td>
      <td> {el.Post_Time ? formatDate(new Date(el.Post_Time), "dd MMM, yyyy hh:mm:a") : "..."}</td>

      <td>
        {el.status === "success" ? (
          <span className=" text-success">Success</span>
        ) : (
          <span className=" text-danger">{el?.status}</span>
        )}{" "}
      </td>
      <td>{el.message}</td>
    </>
  );

  return (
    <Modal show={open} onHide={() => close(false)} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title as={"h6"}>Schedule History</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="">
          <TableComponent
            responsive
            borderless
            striped
            tableHeadsFunction={tableHead}
            mainDataArray={logData || []}
            tableDataRowFunction={displayTableData}
            className="product-table text-nowrap"
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default function ScheduledInvoiceTransactions() {
  useScrollTop();
  const navigate = useNavigate();
  const initialFilterParams = {
    customerName: "",
    customerId: "",
    /*   startDate: format(new Date(), "yyyy-MM-dd"),
      endDate: format(new Date(), "yyyy-MM-dd"), */
    enableDateRange: true,
    DocType: "Invoice",
  };

  const [logHistory, showLogHistory] = useState(false);
  const [logData, showLogData] = useState({});

  const { backendUrl, token } = useAuth();
  const {
    deploymentCurrencies: currenciesOptions,
    prevailingCurrency,
    getCurrencySymbol,
  } = useCurrencies();
  const isCashier = useIsCashier();
  const isAdmin = useIsAdmin();
  const [activeRow, setActiveRow] = useState(null);
  const [showItemsPopover, setShowItemsPopover] = useState(false);
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", true);
  const [btnSummryActive, setBtnSummaryActive] = useState(false);
  const [showMoreSummary, setShowMoreSummary] = useState(true);
  const [excelData, setExcelData] = useState([]);
  const [showTransactionPopover, setShowTransactionPopover] = useState(false);
  const CSVLinkRef = useRef(null);

  // fetch excel hook
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(excelData, CSVLinkRef);

  const close = () => {
    setShowMoreSummary(true);
    setBtnSummaryActive(false);
  };

  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({
      ...queryParams,
      ...debouncedFilterParams,
    });
  }, [debouncedFilterParams]);

  const fetchScheduleTransactions = async queryParams => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/invoice/sheduled-tansactions?${queryString.stringify(queryParams)}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    data.total = data?.transactions?.reduce((acc, curr) => acc + Number(curr.subTotal), 0);

    return data;
  };

  const {
    data = {
      count: 0,
      transactions: [],
    },
    refetch,
    isFetching,
    isSuccess,
  } = useQuery(
    ["GET_SCHEDULE_TRANSACTIONS", queryParams],
    () => fetchScheduleTransactions(queryParams),
    {
      keepPreviousData: true,
    }
  );

  // download excel function
  const { limit, page, ...rest } = queryParams;
  const onDownloadExcelData = async () => {
    let exData = await fetchExcelData(
      `${backendUrl}/api/reports/customer-ledger?${queryString.stringify(rest)}`,
      "GET"
    );

    const company = exData.data?.company?.CompName;
    exData = exData.data.customerLedgers.map(row => ({
      ...row,
      Post_Time: format(new Date(row.Post_Time), "dd-MMM-yyyy hh:mm:ss a"),
    }));

    exData = exData.map(d => [
      d.customer.LastName,
      d.Post_Time,
      d.DocType,
      d.Trans_ID,
      d.PaymentType,
      d.Debit,
      d.Credit,
      d.Username,
      d.InvoiceCat,
    ]);
    const date =
      rest.startDate && rest.endDate
        ? `Date Prepared: Between ${format(
            new Date(rest.startDate),
            "E MMM d yyyy k:mm:ss z"
          )} to ${format(new Date(rest.endDate), "E MMM d yyyy k:mm:ss z")}`
        : "";
    exData = [
      [company],
      ["Customer Invoices and Payments Report"],
      [date],
      [""],
      [
        "Customer Name",
        "Trans Date",
        "Doc Type",
        "Trans ID",
        "Payment Type",
        "Debit Amount",
        "Credit Amount",
        "Username",
        "InvoiceCat",
      ],
      ...exData,
      [""],
      ["Summary"],
      [
        "Total Invoice Amount",
        currency(data?.totalInvoiceAmt, {
          symbol: "",
        }).format(),
      ],
      [
        "Total Payment Amount",
        currency(data?.totalPaymentAmt, {
          symbol: "",
        }).format(),
      ],
      [
        "Balance (LCY)",
        currency(data?.balance, {
          symbol: "",
        }).format(),
      ],
      [
        "Reversed Invoice Amount",
        currency(data?.reversedInvoiceAmount, {
          symbol: "",
        }).format(),
      ],
      [
        "Reversed Payment Amount",
        currency(data?.reversedPaymentAmount, {
          symbol: "",
        }).format(),
      ],
    ];

    setExcelData(exData);
  };

  const handleFilterParamsChange = e => {
    setFilterParams({
      ...filterParams,
      [e.target.name]: e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSearchQueryChange = e => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = date => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
      enableDateRange: true,
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  const reset = () => {
    setFilterParams(initialFilterParams);
    setQueryParams({
      ...queryParams,
      ...initialFilterParams,
    });
  };

  const handleSelectedCustomer = customer => {
    setFilterParams({
      ...filterParams,
      customerId: customer.Cust_ID,
      customerName: customerFullName(customer),
    });
    setShowItemsPopover(false);
  };

  const handleDelete = async ({ id }) => {
    if (
      await ConfirmDialog({
        type: "danger",
        title: "Delete",
        description: "Are you sure, you want to delete ?",
      })
    ) {
      deleteMutation.mutate({ id });
    }
  };

  const tableHead = () => {
    return (
      <thead>
        <tr>
          <td />
          <th>No.</th>
          <th>Date</th>
          <td>Invoice ID</td>
          <td>Amount</td>
          <th>Date Due</th>
          <th>Status</th>
          <th>Pay Due</th>
          <th>Pay Method</th>
        </tr>
      </thead>
    );
  };

  const tableData = (el, index) => {
    return (
      <>
        <td>
          <Dropdown>
            <Dropdown.Toggle variant="" className="bg-white border rounded-3" bsPrefix="print more">
              <DotsHorizontalIcon />
            </Dropdown.Toggle>
            <Dropdown.Menu
              popperConfig={{
                strategy: "fixed",
              }}
              renderOnMount
              className="dropdown-with-icons"
            >
              <Dropdown.Item
                as="button"
                onClick={() => {
                  return el.type === "INVOICE"
                    ? navigate("/invoices/update-reoccurring-invoice", {
                        state: {
                          id: el?.id,
                          transactionData: el.invoice,
                          update: true,
                        },
                      })
                    : el.type === "EXPENSE"
                    ? navigate("/expenses-entry", {
                        state: {
                          id: el?.id,
                          transactionData: el,
                          update: true,
                        },
                      })
                    : el.type === "EXPENSE_BULK"
                    ? navigate("/expenses-entry-bulk", {
                        state: {
                          id: el?.id,
                          transactionData: el,
                          update: true,
                        },
                      })
                    : navigate("/journal-entry", {
                        state: {
                          id: el?.id,
                          transactionData: el,
                          update: true,
                        },
                      });
                }}
              >
                <EditIcon /> Update{" "}
              </Dropdown.Item>

              <Dropdown.Item
                as={"button"}
                onClick={async () => {
                  if (await ConfirmDialog({ title: "Post Now ?" }))
                    triggerMutation.mutate({ id: el.id });
                }}
              >
                <ArrowRightBoldCircleOutlineIcon /> Post now
              </Dropdown.Item>

              <Dropdown.Item
                as={"button"}
                onClick={() => {
                  showLogData(el.log);
                  showLogHistory(true);
                }}
              >
                <DetailedIcon /> Log
              </Dropdown.Item>

              <Dropdown.Divider />

              <Dropdown.Item as={"button"} className="text-danger" onClick={() => handleDelete(el)}>
                <DeleteIcon /> Delete
              </Dropdown.Item>
            </Dropdown.Menu>{" "}
          </Dropdown>
        </td>
        {/* <td className="text-nowrap">
            {formatNumberSystem(el?.numberPrefix, el?.currentNumber)}
          </td> */}
        <td> {Number(data?.startIndex) + index + 1}</td>
        <td>
          {el?.invoice ? JSON.parse(el?.invoice)?.ShipTo || "" : ""} {el.type}
        </td>
        <td>{el.startDate ? formatDate(el.startDate, "dd MMM, yyyy") : "...."}</td>
        <td>{el.nextStartDate ? formatDate(el.nextStartDate, "dd MMM, yyyy") : "...."}</td>
        <td>
          {el.reoccuringEndOption === "None"
            ? "None"
            : el.endDate
            ? formatDate(el.endDate, "dd MMM, yyyy")
            : "...."}
        </td>
        <td>
          {el?.subTotal && el?.subTotal > 0
            ? currency(el.subTotal).format({
                symbol: "",
                separator: ",",
              })
            : "..."}
        </td>
        <td title={`${el.endDate} ${el?.nextStartDate}`}>
          {el.reoccuringEndOption === "None" || !el.reoccuringEndOption
            ? "Active"
            : new Date(el.endDate) > new Date(el?.nextStartDate)
            ? "Active"
            : "Completed"}
        </td>
        <td>{el?.Username || "..."}</td>

        <td>{el.Post_Time ? formatDate(el.Post_Time, "dd MMM, yyyy hh:mm:a") : "...."}</td>
      </>
    );
  };

  const manageTransaction = (event, el) => {
    if (event.detail === 2 && el.Trans_ID) {
      navigate(`/inventory-for-sales/manage-transaction`, {
        state: {
          TransactionID: el.Trans_ID,
        },
      });
    }
  };

  const handleSelectedTransaction = transaction => {
    setFilterParams({
      ...filterParams,
      TransactionID: transaction.TransactionID,
      ...(transaction?.Date_Log
        ? {
            startDate: format(startOfDay(new Date(transaction?.Date_Log)), "yyyy-MM-dd"),
            endDate: format(endOfDay(new Date(transaction?.Date_Log)), "yyyy-MM-dd"),
          }
        : {}),
    });
    setShowTransactionPopover(false);
  };

  const createInvoicesMutation = useMutation(
    payload =>
      fetchActionsUtil(`${backendUrl}/api/invoice/create-invoice-multiple`, "POST", token, payload),
    {
      onSuccess: ({ message }) => {
        toast.success(message);
        if (refetch) refetch();
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  const triggerMutation = useMutation(
    payload =>
      fetchActionsUtil(`${backendUrl}/api/invexcloud/trigger-schedule`, "POST", token, payload),
    {
      onSuccess: ({ message }) => {
        toast.success(message);
        if (refetch) refetch();
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  const deleteMutation = useMutation(
    payload =>
      fetchActionsUtil(`${backendUrl}/api/invexcloud/delete-schedule`, "POST", token, payload),
    {
      onSuccess: ({ message }) => {
        toast.success(message);
        if (refetch) refetch();
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  return (
    <>
      <IsPrivileged roleName={["Sale by Invoice", "Invoice List", "Invoices"]}>
        <InvoiceInnerPageLayout
          name={"Scheduled Transactions"}
          icon={<ClockIcon />}
          description={"Your current scheduled transaction summary and activity"}
        />
        <main className="cash-book ">
          <div className="content border rounded-4">
            <div className="content-main">
              <div className={` mt-2 filter-area ${showFilter ? "open" : "close"}`}>
                <div className="filter-header">
                  <h2>
                    <FilterIcon color="#94A3B8" /> Filter
                  </h2>

                  <Button variant="white" onClick={() => setShowFilter(!showFilter)}>
                    <FilterCollapseIcon />
                  </Button>
                </div>
                <div className="filter-body ">
                  <Form>
                    <div className="body">
                      {" "}
                      {/*   <Form.Group className="mb-2-5">
                        <Form.Label>Transaction ID</Form.Label>{" "}
                        <InputGroup>
                          <Form.Control
                            name="TransactionID"
                            value={filterParams?.TransactionID}
                            placeholder="Enter Transaction ID"
                            onChange={(e) => handleFilterParamsChange(e)}
                          />{" "}
                          <Popover
                            isOpen={showTransactionPopover}
                            onClickOutside={() =>
                              setShowTransactionPopover(false)
                            }
                            content={() => (
                              <TransactionsTable
                                handleSelectedTransaction={
                                  handleSelectedTransaction
                                }
                              />
                            )}
                            position="bottom"
                          >
                            <InputGroup.Text
                              onClick={() =>
                                setShowTransactionPopover(
                                  !showTransactionPopover
                                )
                              }
                            >
                              <MagnifyIcon />
                            </InputGroup.Text>
                          </Popover>{" "}
                        </InputGroup>
                      </Form.Group> */}
                      <Form.Group className="mb-2-5">
                        <Form.Label>Customer ID</Form.Label>
                        <InputGroup>
                          <Form.Control
                            name="customerId"
                            placeholder="Enter Customer ID"
                            value={filterParams?.customerId}
                            onChange={e => handleFilterParamsChange(e)}
                          />
                          <Popover
                            isOpen={showItemsPopover}
                            onClickOutside={() => setShowItemsPopover(false)}
                            content={() => (
                              <CustomerSelector
                                handleSelectedCustomer={handleSelectedCustomer}
                                setShowCustomerSelector={setShowItemsPopover}
                              />
                            )}
                            position="bottom"
                          >
                            <InputGroup.Text onClick={() => setShowItemsPopover(!showItemsPopover)}>
                              <MagnifyIcon />
                            </InputGroup.Text>
                          </Popover>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group className="mb-2-5">
                        <Form.Label>Customer Name</Form.Label>
                        <Form.Control
                          name="customerName"
                          value={filterParams?.customerName}
                          placeholder="Enter Customer Name"
                          onChange={e => handleFilterParamsChange(e)}
                        />
                      </Form.Group>
                      {/*  <Form.Group className="mb-2-5">
                      <Form.Label>Invoice Cat</Form.Label>
                      <Select
                        classNamePrefix="form-select"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        placeholder="All"
                        theme={reactSelectTheme}
                        isSearchable={false}
                        key={data?.invoiceCat}
                        defaultValue={defaultSelectValue(
                          queryParams?.invoiceCat,
                          data?.invoiceCat,
                          {
                            value: "",
                            label: "All",
                          }
                        )}
                        options={data?.invoiceCat || []}
                        onChange={(selected) => {
                          setFilterParams({
                            ...filterParams,
                            invoiceCat: selected.value,
                          });
                        }}
                      />
                    </Form.Group> */}
                      <hr className="mt-3 mb-4" />
                      {/* <div className="mb-2-5">
                        <Form.Check
                          inline
                          label="Enable Date Range"
                          name="enableDateRange"
                          type={"checkbox"}
                          id={`enableDateRange`}
                          checked={filterParams?.enableDateRange}
                          onChange={(e) => handleFilterParamsChange(e)}
                        />
                      </div> */}
                      <Form.Group className="">
                        <Form.Label>Date Range</Form.Label>
                        <div className="position-relative">
                          <RsDateRangePicker
                            placement="topStart"
                            value={
                              filterParams.startDate && filterParams.endDate
                                ? [
                                    parse(filterParams.startDate, "yyyy-MM-dd", new Date()),
                                    parse(filterParams.endDate, "yyyy-MM-dd", new Date()),
                                  ]
                                : []
                            }
                            onClean={() => clearDateRange()}
                            onOk={date => filterByDateRange(date)}
                          />
                        </div>
                      </Form.Group>
                    </div>

                    <div className="buttons rounded">
                      <Button onClick={() => reset()} variant="white">
                        Reset
                      </Button>
                      <Button onClick={() => search()} variant="primary">
                        Search
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
              <div className="content-body">
                <header>
                  <h1>
                    Scheduled Transactions
                    <button title="Refresh" onClick={() => refetch()} className="btn text-primary">
                      <CachedIcon />
                    </button>
                  </h1>
                  <div
                    className="actions"
                    style={{
                      zIndex: "10",
                    }}
                  >
                    <div className="global-search-area">
                      <MagnifyIcon />
                      <Form.Control
                        name="customerName"
                        value={filterParams?.customerName}
                        placeholder="Search..."
                        onChange={e => handleFilterParamsChange(e)}
                        className=" rounded-4 border"
                      />
                    </div>
                    {/* <PrivilegedAction
                          eachRole={{
                            name: "Select Currency",
                            parent: "Invoice List",
                          }}
                        >
                          <Form.Group>
                            <Select
                              classNamePrefix="form-select"
                              placeholder="Select Currency"
                              isSearchable={false}
                              options={currenciesOptions}
                              value={currenciesOptions.find(
                                (el) => el.value == filterParams.currency
                              )}
                              onChange={({ value }) => {
                                setFilterParams({
                                  ...filterParams,
                                  currency: value,
                                });
                              }}
                            />
                          </Form.Group>
                        </PrivilegedAction> */}

                    {/* <CSVLink
                        className="btn print d-none"
                        filename={`Invoices(${format(
                          new Date(),
                          "dd-MMM-yyyy hh:mm:ss a"
                        )}).csv`}
                        data={excelData}
                        ref={CSVLinkRef}
                      /> */}

                    {/* {(isAdmin || isCashier) && (
                        <label
                          variant=""
                          className="btn print"
                          title="Import"
                          bsPrefix=""
                        >
                          <input
                            type="file"
                            className="d-none"
                            onChange={(e) =>
                              handleFile({
                                eventData: e,
                                type: "",
                              })
                            }
                            accept=".xlsx"
                          />
                          Import <ExcelIcon color="#008000" />
                        </label>
                      )} */}

                    {/* <PrivilegedAction
                        eachRole={{ name: "Export", parent: "Invoice List" }}
                      >
                        <Dropdown>
                          <Dropdown.Toggle
                            variant=""
                            className="btn print"
                            disabled={isfetchingExcel}
                            bsPrefix=""
                          >
                            Export
                            <ExportIcon color="#008000" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            popperConfig={{
                              strategy: "fixed",
                            }}
                            renderOnMount
                            className="text-center"
                          >
                            <Dropdown.Item
                              as="div"
                              onClick={onDownloadExcelData}
                              className="p-cursor"
                            >
                              Excel <ExcelIcon color="#008000" />
                            </Dropdown.Item>
                            <Dropdown.Item as="div">
                              <a
                                href={`${backendUrl}/api/reports/pdf/sale-by-invoice?${queryString.stringify(
                                  rest
                                )}`}
                                target="_blank"
                              >
                                PDF <PDFIcon color="#ff0000" />
                              </a>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </PrivilegedAction> */}

                    {/* <PrivilegedAction
                        eachRole={{
                          name: "Show Summary",
                          parent: "Invoice List",
                        }}
                      >
                        <button
                          onClick={() => setBtnSummaryActive(true)}
                          className="btn summaryBtn "
                        >
                          Show Summary
                        </button>
                      </PrivilegedAction> */}

                    {!showFilter && (
                      <Button
                        variant=""
                        onClick={() => setShowFilter(!showFilter)}
                        className="btn filter ms-2 border rounded-4 d-flex align-items-center px-3 gap-2"
                        style={{
                          height: "40px",
                          lineHeight: "40px",
                        }}
                      >
                        Filter <FilterIcon color="#94A3B8" />
                      </Button>
                    )}
                  </div>
                </header>

                <div className="px-md-4">
                  <Table responsive borderless hover className="text-nowrap product-table">
                    <thead className="bg-white">
                      <tr className=" border-bottom border-top">
                        <th className="py-3" />
                        <th className="py-3">S/N</th>
                        <th className="py-3">Customer / Type</th>
                        <th className="py-3">Start Date</th>
                        <th className="py-3">Next Schedule Date</th>
                        <th className="py-3">End Date</th>
                        <th className="py-3">Sub Total</th>
                        <th className="py-3">Status</th>
                        <th className="py-3">Created By</th>
                        <th className="py-3">Created At</th>
                      </tr>
                    </thead>
                    <tbody className="blue-hover mt-3">
                      {data?.transactions?.map((el, index) => (
                        <tr className=" ">{tableData(el, index)}</tr>
                      ))}
                    </tbody>
                  </Table>

                  {!isFetching && isSuccess && isEmpty(data?.transactions) ? (
                    <NoTableItem queryParams={queryParams} />
                  ) : null}
                </div>

                <div className="d-flex justify-content-between px-3 align-items-center pagination">
                  <div className="pagination_left">
                    <p className="m-0 p-0">Show</p>
                    <select
                      value={queryParams.limit}
                      name="limit"
                      className="form-select "
                      onChange={e => handleSearchQueryChange(e)}
                    >
                      <option value="10">10 rows</option>
                      <option value="20">20 rows</option>
                      <option value="30">30 rows</option>
                      <option value="40">40 rows</option>
                      <option value="50">50 rows</option>
                      <option value="100">100 rows</option>{" "}
                    </select>
                  </div>

                  <ReactPaginate
                    {...paginationOptions}
                    pageCount={Math.ceil(data.count / queryParams.limit)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={0}
                    onPageChange={({ selected }) => {
                      document.body.scrollTop = document.documentElement.scrollTop = 0;
                      setQueryParams({
                        ...queryParams,
                        page: selected + 1,
                      });
                    }}
                    forcePage={queryParams.page - 1}
                  />
                </div>

                <div
                  className={
                    btnSummryActive ? `summaryNew border-top open` : `summaryNew border-top`
                  }
                >
                  <div className="summaryHeader">
                    <h2>Summary</h2>
                    {btnSummryActive && (
                      <button onClick={() => close()} className="btn p-0">
                        <SummaryCloseIcon />
                      </button>
                    )}
                  </div>

                  <div className="gridCont">
                    {/*  <div className="gridChild gridChildBorderLeftBlue">
                    <div className="gridChildLeft">
                      <p className="gridChld1">
                        {currency(data?.totalInvoiceAmt, {
                          symbol: "",
                        }).format()}
                      </p>
                      <p className="gridChld2">Total Invoice Amt (All)</p>
                    </div>

                    <div className="gridChildRight">
                      <SummaryChartIconUp />
                      <p>5%</p>
                    </div>
                  </div> */}

                    <div className="gridChild gridChildBorderLeftOrange">
                      <div className="gridChildLeft">
                        <p className="gridChld1">
                          {currency(
                            filterParams.currency === prevailingCurrency || !filterParams.currency
                              ? data?.total
                              : 0,
                            {
                              symbol: getCurrencySymbol(prevailingCurrency),
                            }
                          ).format()}
                        </p>
                        <p className="gridChld2">
                          Total {prevailingCurrency} ({getCurrencySymbol(prevailingCurrency)})
                        </p>
                      </div>
                    </div>

                    {/* <div className="gridChild gridChildBorderLeftBrown">
                    <div className="gridChildLeft">
                      <p className="gridChld1">
                        {currency(
                          filterParams.currency === "USD" ||
                            !filterParams.currency
                            ? data?.totalDollar
                            : 0,
                          {
                            symbol: "$",
                          }
                        ).format()}
                      </p>
                      <p className="gridChld2">Total Invoice in USD ($)</p>
                    </div>

                    <div className="gridChildRight">
                      <SummaryChartIconDown />
                      <p>5%</p>
                    </div>
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ModalLoader show={isfetchingExcel || createInvoicesMutation.isLoading} />
        </main>

        {logHistory && <LogModal open={logHistory} close={showLogHistory} logData={logData} />}

        <ModalLoader show={triggerMutation.isLoading || deleteMutation.isLoading} />
      </IsPrivileged>
    </>
  );
}

export const CustomInvoiceButton = () => {
  const navigate = useNavigate();

  return (
    <Dropdown>
      <Dropdown.Toggle variant="" bsPrefix="change" className=" border-0">
        <Button className="d-flex text-nowrap align-items-center  me-n2 bg-primary-invoice-btn">
          Create Invoice <ChevronDownIcon />
        </Button>
      </Dropdown.Toggle>
      <Dropdown.Menu
        popperConfig={{
          strategy: "fixed",
        }}
        renderOnMount
        className=" border rounded-4"
      >
        <Dropdown.Item
          as={"button"}
          onClick={() => navigate("/invoices/create-standard-invoice")}
          variant=""
          className=" d-flex gap-1 align-items-center"
        >
          <StandardInvoiceIcon />
          Standard Invoice
        </Dropdown.Item>
        <Dropdown.Item
          as={"button"}
          onClick={() => navigate("/invoices/create-reoccurring-invoice")}
          variant=""
          className=" d-flex gap-1 align-items-center"
        >
          <ReoccurringInvoiceIcon />
          Reoccurring Invoice
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
