import { Dropdown, Table, Form, Button, InputGroup, Image } from "react-bootstrap";
import { CSVLink } from "react-csv";
import DetailedIcon, {
  ExportIcon,
  FilterIcon,
  FilterTwoIcon,
  BusinessBillIcon,
  PrintIcon,
  SummaryChartIconUp,
  SummaryChartIconDown,
  SummaryCloseIcon,
  FilterCollapseIcon,
  RecieptIcon,
  PageHeaderListIcon,
  BookIcon,
  ExcelIcon,
  PDFIcon,
  NoSelectedItemIcon,
  DeleteIcon,
  LinkVariantIcon,
  ReoccurringInvoiceIcon,
  StandardInvoiceIcon,
} from "../Icons";
import "../../assets/scss/reports/cashbook.scss";
import "../../assets/scss/dashboard.scss";
import { useEffect, useRef, useState } from "react";
import CachedIcon from "mdi-react/CachedIcon";
import Select from "react-select";
import useDebounce, {
  useCurrencies,
  useIsAdmin,
  useIsCashier,
  useLocalStorage,
  useQueryParams,
  useScrollTop,
} from "../../utils/hooks";
import { appSettings, services } from "../../config";
import { reportActions } from "../../utils/reactQueryActions";
import { useQuery, useMutation } from "react-query";
import currency from "currency.js";
import { endOfDay, format, parse, startOfDay } from "date-fns";
import ReactPaginate from "react-paginate";
import {
  copyText,
  customerFullName,
  defaultSelectValue,
  fetchActionsUtil,
  formatDate,
  formatNumberSystem,
  paginationOptions,
  reactSelectTheme,
} from "../../utils/helpers";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import queryString from "query-string";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import { useAuth } from "../../hooks/useAuth";
import { Link, useNavigate } from "react-router-dom";
import CustomerSelector from "../utils/CustomerSelector";
import { Popover } from "react-tiny-popover";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { lowerCase, truncate } from "lodash";
import { isEmpty } from "lodash";
import NoTableItem from "../utils/NoTableItem";
import { useDownloadExcel } from "../../hooks/useDownloadExcel";
import ModalLoader from "../utils/ModalLoader";
import TableComponent from "../TableComponent";
import TransactionsTable from "../utils/TransactionsTable";
import { currencies } from "../../utils/currencies";
import { IsPrivileged } from "../DisplayChildElement";
import PrivilegedAction from "../PrivilegedAction";
import { toast } from "react-toastify";
import * as yup from "yup";
import ConfirmDialog from "../ConfirmDialogue";
import { read, utils } from "xlsx";
import InvoiceLayout from "./InvoiceWrapper";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import EyeOffOutlineIcon from "mdi-react/EyeOffOutlineIcon";
import EyeOutlineIcon from "mdi-react/EyeOutlineIcon";
import CustomInvoiceTableComponent from "../CustomInvoiceTableComponent";
import InvoiceAnalysisChart from "./InvoiceAnalysisChart";
import InvoiceAnalysisPieChart from "./InvoiceAnalysisPieChart";
import CustomerAvatar from "../utils/CustomerAvatar";
/* const currenciesOptions = [
  {
    label: "All Currencies",
    value: "",
  },
  ...currencies.map((el) => ({
    ...el,
    label: `${el.name} (${el.symbol})`,
    value: el.cc,
  })),
]; */

export default function NewCustominvoice() {
  useScrollTop();
  const navigate = useNavigate();
  const initialFilterParams = {
    customerName: "",
    customerId: "",
    startDate: format(new Date(), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"),
    enableDateRange: true,
    DocType: "Invoice",
  };

  const { backendUrl, token } = useAuth();
  const {
    deploymentCurrencies: currenciesOptions,
    prevailingCurrency,
    getCurrencySymbol,
  } = useCurrencies();
  const isCashier = useIsCashier();
  const isAdmin = useIsAdmin();
  const [activeRow, setActiveRow] = useState(null);
  const [showItemsPopover, setShowItemsPopover] = useState(false);
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", true);
  const [btnSummryActive, setBtnSummaryActive] = useState(false);
  const [showMoreSummary, setShowMoreSummary] = useState(true);
  const [excelData, setExcelData] = useState([]);
  const [showTransactionPopover, setShowTransactionPopover] = useState(false);
  const CSVLinkRef = useRef(null);

  // fetch excel hook
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(excelData, CSVLinkRef);

  const close = () => {
    setShowMoreSummary(true);
    setBtnSummaryActive(false);
  };

  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({
      ...queryParams,
      ...debouncedFilterParams,
    });
  }, [debouncedFilterParams]);

  const fetchCustomerLedgers = async queryParams => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/reports/customer-ledger?&${queryString.stringify(queryParams)}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    data.invoiceCat = data.invoiceCat.map(el => ({
      value: el,
      label: el,
    }));
    return data;
  };

  const {
    data = {
      count: 0,
      customerLedgers: [],
    },
    refetch,
    isFetching,
    isSuccess,
  } = useQuery(
    [reportActions.SALE_BY_INVOICE, queryParams],
    () => fetchCustomerLedgers(queryParams),
    {
      keepPreviousData: true,
    }
  );

  // download excel function
  const { limit, page, ...rest } = queryParams;
  const onDownloadExcelData = async () => {
    let exData = await fetchExcelData(
      `${backendUrl}/api/reports/customer-ledger?${queryString.stringify(rest)}`,
      "GET"
    );

    const company = exData.data?.company?.CompName;
    exData = exData.data.customerLedgers.map(row => ({
      ...row,
      Post_Time: format(new Date(row.Post_Time), "dd-MMM-yyyy hh:mm:ss a"),
    }));

    exData = exData.map(d => [
      d.customer.LastName,
      d.Post_Time,
      d.DocType,
      d.Trans_ID,
      d.PaymentType,
      d.Debit,
      d.Credit,
      d.Username,
      d.InvoiceCat,
    ]);
    const date =
      rest.startDate && rest.endDate
        ? `Date Prepared: Between ${format(
            new Date(rest.startDate),
            "E MMM d yyyy k:mm:ss z"
          )} to ${format(new Date(rest.endDate), "E MMM d yyyy k:mm:ss z")}`
        : "";
    exData = [
      [company],
      ["Customer Invoices and Payments Report"],
      [date],
      [""],
      [
        "Customer Name",
        "Trans Date",
        "Doc Type",
        "Trans ID",
        "Payment Type",
        "Debit Amount",
        "Credit Amount",
        "Username",
        "InvoiceCat",
      ],
      ...exData,
      [""],
      ["Summary"],
      [
        "Total Invoice Amount",
        currency(data?.totalInvoiceAmt, {
          symbol: "",
        }).format(),
      ],
      [
        "Total Payment Amount",
        currency(data?.totalPaymentAmt, {
          symbol: "",
        }).format(),
      ],
      [
        "Balance (LCY)",
        currency(data?.balance, {
          symbol: "",
        }).format(),
      ],
      [
        "Reversed Invoice Amount",
        currency(data?.reversedInvoiceAmount, {
          symbol: "",
        }).format(),
      ],
      [
        "Reversed Payment Amount",
        currency(data?.reversedPaymentAmount, {
          symbol: "",
        }).format(),
      ],
    ];
    console.log(exData);
    setExcelData(exData);
  };

  const handleFilterParamsChange = e => {
    setFilterParams({
      ...filterParams,
      [e.target.name]: e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSearchQueryChange = e => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = date => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
      enableDateRange: true,
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  const reset = () => {
    setFilterParams(initialFilterParams);
    setQueryParams({
      ...queryParams,
      ...initialFilterParams,
    });
  };

  const handleSelectedCustomer = customer => {
    setFilterParams({
      ...filterParams,
      customerId: customer.Cust_ID,
      customerName: customerFullName(customer),
    });
    setShowItemsPopover(false);
  };

  const tableHead = () => {
    return (
      <thead>
        <tr>
          <th>No</th>
          <th>Date</th>
          <td>Invoice ID</td>
          <td>Amount</td>
          <th>Date Due</th>
          <th>Status</th>
          <th>Pay Due</th>
          <th>Pay Method</th>
          <td>Action</td>
        </tr>
      </thead>
    );
  };

  const tableData = (el, index) => {
    return (
      <>
        {/* <td className="text-nowrap">
          {formatNumberSystem(el?.numberPrefix, el?.currentNumber)}
        </td> */}
        <td>{el.Post_Time ? formatDate(el.Post_Time, "dd MMM, yyyy") : ""}</td>

        <td>{el?.Trans_ID}</td>
        <td className=" ">
          <div className="d-flex gap-1  align-items-center">
            <div
              style={{
                width: "24px",
                height: "24px",
                borderRadius: "50%",
              }}
            >
              <CustomerAvatar
                className="avatar"
                userId={el?.Cust_ID}
                Cust_ID={el?.Cust_ID}
                name={
                  el?.customer?.LastName === "Walk-In" && el?.transaction
                    ? `${el?.transaction?.ShipTo} (Walk-In)`
                    : customerFullName(el.customer)
                }
                rounded={true}
              />
            </div>

            {truncate(
              el?.customer?.LastName === "Walk-In" && el?.transaction
                ? `${el?.transaction?.ShipTo} (Walk-In)`
                : customerFullName(el.customer),
              {
                length: 12,
                separator: "...",
              }
            )}
          </div>
        </td>
        <td>
          {" "}
          {el.currency && el.currency !== prevailingCurrency
            ? currency(el.Debit, {
                symbol: getCurrencySymbol(el.currency),
              })
                .divide(el.conversionAmount)
                .format()
            : currency(el.Debit, {
                symbol: getCurrencySymbol(el.currency),
              }).format()}
        </td>
        {/* <td>{el.DocType}</td> */}
        <td>
          {el.transaction?.DueDate ? formatDate(el.transaction?.DueDate, "dd MMM, yyyy") : "..."}
        </td>

        <td title={el.status}>{el?.status ? el.status : "..."}</td>
        {/* <td>{el.Username}</td> */}

        <td>{el?.transaction?.Payment_Type}</td>
        <td>
          <div className=" d-flex  align-items-center gap-2">
            <Dropdown>
              <Dropdown.Toggle
                variant=""
                className="bg-white border rounded-3 "
                bsPrefix="print more"
                onClick={() => {
                  navigate(`/inventory-for-sales/manage-transaction`, {
                    state: {
                      TransactionID: el.Trans_ID,
                    },
                  });
                }}
              >
                <EyeOutlineIcon />
              </Dropdown.Toggle>
            </Dropdown>
            {["invoice", "official receipt"].includes(lowerCase(el.DocType)) && (
              <Dropdown>
                <Dropdown.Toggle
                  variant=""
                  className="bg-white border rounded-3"
                  bsPrefix="print more"
                >
                  <DotsHorizontalIcon />
                </Dropdown.Toggle>
                <Dropdown.Menu
                  popperConfig={{
                    strategy: "fixed",
                  }}
                  renderOnMount
                  className="dropdown-with-icons"
                >
                  {lowerCase(el.DocType) === "invoice" && (
                    <>
                      <Dropdown.Item as="div">
                        <a
                          href={`${backendUrl}/api/invoice/pdf/invoice/${el.Trans_ID}`}
                          target="_blank"
                        >
                          <BookIcon />
                          Open Invoice - A4
                        </a>
                      </Dropdown.Item>
                      <Dropdown.Item as="div">
                        <a
                          href={`${backendUrl}/api/invoice/pdf/invoice/${
                            el.Trans_ID
                          }?thermalPrinter=true`}
                          target="_blank"
                        >
                          <BookIcon />
                          Open Invoice - Thermal Printer
                        </a>
                      </Dropdown.Item>
                      <Dropdown.Item as="button">
                        <a
                          href={`${backendUrl}/api/invoice/pdf/waybill/${el.Trans_ID}`}
                          target="_blank"
                        >
                          <PageHeaderListIcon />
                          Open Waybill
                        </a>
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="button"
                        onClick={() =>
                          copyText(
                            `${
                              services.frontendUrl
                            }/sales-and-invoicing/delete-invoice?TransactionID=${el.Trans_ID}`
                          )
                        }
                      >
                        <LinkVariantIcon /> Copy Delete Invoice Link
                      </Dropdown.Item>
                      {isAdmin || (appSettings.isGigc && isCashier) ? (
                        <Dropdown.Item as="div">
                          <Link
                            to={`/sales-and-invoicing/delete-invoice?TransactionID=${el.Trans_ID}`}

                            // target="_blank"
                          >
                            <DeleteIcon /> Delete Invoice
                          </Link>
                        </Dropdown.Item>
                      ) : null}
                    </>
                  )}
                  {lowerCase(el.DocType) === "official receipt" && (
                    <>
                      <Dropdown.Item as="div">
                        <a
                          href={`${backendUrl}/api/invoice/pdf/receipt/${el.Trans_ID}`}
                          target="_blank"
                        >
                          <RecieptIcon />
                          Open Reciept - A4
                        </a>
                      </Dropdown.Item>

                      <Dropdown.Item as="div">
                        <a
                          href={`${backendUrl}/api/invoice/pdf/receipt/${
                            el.Trans_ID
                          }?thermalPrinter=true`}
                          target="_blank"
                        >
                          <RecieptIcon />
                          Open Reciept - Thermal Printer
                        </a>
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="button"
                        onClick={() =>
                          copyText(
                            `${
                              services.frontendUrl
                            }/sales-and-invoicing/delete-payment?TransactionID=${
                              el.Trans_ID
                            }&PaymentType=${el.PaymentType}&Balance=${el.Balance}`
                          )
                        }
                      >
                        <LinkVariantIcon /> Copy Delete Payment Link
                      </Dropdown.Item>
                      {isAdmin || (appSettings.isGigc && isCashier) ? (
                        <Dropdown.Item onClick={() => navigate()} as="div">
                          <Link
                            to={`/sales-and-invoicing/delete-payment?TransactionID=${
                              el.Trans_ID
                            }&PaymentType=${el.PaymentType}&Balance=${el.Balance}`}
                          >
                            <DeleteIcon /> Delete Payment
                          </Link>
                        </Dropdown.Item>
                      ) : null}
                    </>
                  )}
                </Dropdown.Menu>{" "}
              </Dropdown>
            )}
          </div>
        </td>
      </>
    );
  };

  const manageTransaction = (event, el) => {
    if (event.detail === 2 && el.Trans_ID) {
      navigate(`/inventory-for-sales/manage-transaction`, {
        state: {
          TransactionID: el.Trans_ID,
        },
      });
    }
  };

  const handleSelectedTransaction = transaction => {
    setFilterParams({
      ...filterParams,
      TransactionID: transaction.TransactionID,
      ...(transaction?.Date_Log
        ? {
            startDate: format(startOfDay(new Date(transaction?.Date_Log)), "yyyy-MM-dd"),
            endDate: format(endOfDay(new Date(transaction?.Date_Log)), "yyyy-MM-dd"),
          }
        : {}),
    });
    setShowTransactionPopover(false);
  };

  const createInvoicesMutation = useMutation(
    payload =>
      fetchActionsUtil(`${backendUrl}/api/invoice/create-invoice-multiple`, "POST", token, payload),
    {
      onSuccess: ({ message }) => {
        toast.success(message);
        if (refetch) refetch();
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  function handleFile({ eventData: e, type }) {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async function(e) {
        const workbook = read(e.target.result, {
          cellDates: true,
          dateNF: "yyyy-MM-dd HH:mm:ss.SSS",
        });
        let rows = utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {
          defval: "",
          dateNF: "yyyy-MM-dd HH:mm:ss.SSS",
        });

        if (
          !(await ConfirmDialog({
            title: "Are you sure?",
            description: "You want to import",
          }))
        ) {
          return;
        }
        // TODO - validate excel
        let schema = yup.object().shape({
          items: yup.array().of(
            yup.object({
              currency: yup.string().required(),
              conversionAmount: yup.string().required(),
              Item_Name: yup.string().required(),
              Amount: yup.string().required(),
              Cust_ID: yup.string().required(),
              jobNumber: yup.string().required(),
              jobTitle: yup.string().required(),
              Date_Log: yup.string().required(),
              poNumber: yup.string().required(),
            })
          ),
        });
        try {
          await schema.validate({ items: rows });

          // Clean Up
          /* rows = rows.map((el) => ({
            Item_Name: el.Item_Name,
            Bar_Code: el.Item_Code,
            QtyCounted: el?.Quantity ? el?.Quantity : el?.Quantity_Counted,
          })); */

          createInvoicesMutation.mutate({ invoices: rows });
        } catch (err) {
          console.log(err);
          toast.error(err.name);
          toast.error(JSON.stringify(err.errors));
        }
      };
      reader.readAsArrayBuffer(file);
    }
    e.target.value = "";
  }

  return (
    <IsPrivileged roleName={["Sale by Invoice", "Invoice List", "Invoices"]}>
      <InvoiceLayout>
        <section className={`custom-stats mb-3  custom-two-third-column`}>
          <div className="custom-stat-chart border rounded-4 ">
            <InvoiceAnalysisChart />

            {/* <RevenueVsExpenses /> */}
          </div>
          <div className="custom-stat-chart  border rounded-4">
            <InvoiceAnalysisPieChart />
          </div>
        </section>
        <main className="cash-book ">
          <div className="content border rounded-4">
            <div className="content-main">
              <div className={` mt-2 filter-area ${showFilter ? "open" : "close"}`}>
                <div className="filter-header">
                  <h2>
                    <FilterIcon color="#94A3B8" /> Filter
                  </h2>

                  <Button variant="white" onClick={() => setShowFilter(!showFilter)}>
                    <FilterCollapseIcon />
                  </Button>
                </div>
                <div className="filter-body ">
                  <Form>
                    <div className="body">
                      {" "}
                      <Form.Group className="mb-2-5">
                        <Form.Label>Transaction ID</Form.Label>{" "}
                        <InputGroup>
                          <Form.Control
                            name="TransactionID"
                            value={filterParams?.TransactionID}
                            placeholder="Enter Transaction ID"
                            onChange={e => handleFilterParamsChange(e)}
                          />{" "}
                          <Popover
                            isOpen={showTransactionPopover}
                            onClickOutside={() => setShowTransactionPopover(false)}
                            content={() => (
                              <TransactionsTable
                                handleSelectedTransaction={handleSelectedTransaction}
                              />
                            )}
                            position="bottom"
                          >
                            <InputGroup.Text
                              onClick={() => setShowTransactionPopover(!showTransactionPopover)}
                            >
                              <MagnifyIcon />
                            </InputGroup.Text>
                          </Popover>{" "}
                        </InputGroup>
                      </Form.Group>
                      <Form.Group className="mb-2-5">
                        <Form.Label>Customer ID</Form.Label>
                        <InputGroup>
                          <Form.Control
                            name="customerId"
                            placeholder="Enter Customer ID"
                            value={filterParams?.customerId}
                            onChange={e => handleFilterParamsChange(e)}
                          />
                          <Popover
                            isOpen={showItemsPopover}
                            onClickOutside={() => setShowItemsPopover(false)}
                            content={() => (
                              <CustomerSelector
                                handleSelectedCustomer={handleSelectedCustomer}
                                setShowCustomerSelector={setShowItemsPopover}
                              />
                            )}
                            position="bottom"
                          >
                            <InputGroup.Text onClick={() => setShowItemsPopover(!showItemsPopover)}>
                              <MagnifyIcon />
                            </InputGroup.Text>
                          </Popover>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group className="mb-2-5">
                        <Form.Label>Customer Name</Form.Label>
                        <Form.Control
                          name="customerName"
                          value={filterParams?.customerName}
                          placeholder="Enter Customer Name"
                          onChange={e => handleFilterParamsChange(e)}
                        />
                      </Form.Group>
                      {/*  <Form.Group className="mb-2-5">
                    <Form.Label>Invoice Cat</Form.Label>
                    <Select
                      classNamePrefix="form-select"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      placeholder="All"
                      theme={reactSelectTheme}
                      isSearchable={false}
                      key={data?.invoiceCat}
                      defaultValue={defaultSelectValue(
                        queryParams?.invoiceCat,
                        data?.invoiceCat,
                        {
                          value: "",
                          label: "All",
                        }
                      )}
                      options={data?.invoiceCat || []}
                      onChange={(selected) => {
                        setFilterParams({
                          ...filterParams,
                          invoiceCat: selected.value,
                        });
                      }}
                    />
                  </Form.Group> */}
                      <hr className="mt-3 mb-4" />
                      <div className="mb-2-5">
                        <Form.Check
                          inline
                          label="Enable Date Range"
                          name="enableDateRange"
                          type={"checkbox"}
                          id={`enableDateRange`}
                          checked={filterParams?.enableDateRange}
                          onChange={e => handleFilterParamsChange(e)}
                        />
                      </div>
                      <Form.Group className="">
                        <Form.Label>Date Range</Form.Label>
                        <div className="position-relative">
                          <RsDateRangePicker
                            placement="topStart"
                            value={
                              filterParams.startDate && filterParams.endDate
                                ? [
                                    parse(filterParams.startDate, "yyyy-MM-dd", new Date()),
                                    parse(filterParams.endDate, "yyyy-MM-dd", new Date()),
                                  ]
                                : []
                            }
                            onClean={() => clearDateRange()}
                            onOk={date => filterByDateRange(date)}
                          />
                        </div>
                      </Form.Group>
                    </div>

                    <div className="buttons rounded">
                      <Button onClick={() => reset()} variant="white">
                        Reset
                      </Button>
                      <Button onClick={() => search()} variant="primary">
                        Search
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
              <div className="content-body">
                <header>
                  <h1>
                    Invoice List
                    <button title="Refresh" onClick={() => refetch()} className="btn text-primary">
                      <CachedIcon />
                    </button>
                  </h1>
                  <div
                    className="actions"
                    style={{
                      zIndex: "10",
                    }}
                  >
                    <div className="global-search-area">
                      <MagnifyIcon />
                      <Form.Control
                        name="customerName"
                        value={filterParams?.customerName}
                        placeholder="Search..."
                        onChange={e => handleFilterParamsChange(e)}
                        className=" rounded-4 border"
                      />
                    </div>
                    {/* <PrivilegedAction
                        eachRole={{
                          name: "Select Currency",
                          parent: "Invoice List",
                        }}
                      >
                        <Form.Group>
                          <Select
                            classNamePrefix="form-select"
                            placeholder="Select Currency"
                            isSearchable={false}
                            options={currenciesOptions}
                            value={currenciesOptions.find(
                              (el) => el.value == filterParams.currency
                            )}
                            onChange={({ value }) => {
                              setFilterParams({
                                ...filterParams,
                                currency: value,
                              });
                            }}
                          />
                        </Form.Group>
                      </PrivilegedAction> */}

                    {/* <CSVLink
                      className="btn print d-none"
                      filename={`Invoices(${format(
                        new Date(),
                        "dd-MMM-yyyy hh:mm:ss a"
                      )}).csv`}
                      data={excelData}
                      ref={CSVLinkRef}
                    /> */}

                    {/* {(isAdmin || isCashier) && (
                      <label
                        variant=""
                        className="btn print"
                        title="Import"
                        bsPrefix=""
                      >
                        <input
                          type="file"
                          className="d-none"
                          onChange={(e) =>
                            handleFile({
                              eventData: e,
                              type: "",
                            })
                          }
                          accept=".xlsx"
                        />
                        Import <ExcelIcon color="#008000" />
                      </label>
                    )} */}

                    {/* <PrivilegedAction
                      eachRole={{ name: "Export", parent: "Invoice List" }}
                    >
                      <Dropdown>
                        <Dropdown.Toggle
                          variant=""
                          className="btn print"
                          disabled={isfetchingExcel}
                          bsPrefix=""
                        >
                          Export
                          <ExportIcon color="#008000" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          popperConfig={{
                            strategy: "fixed",
                          }}
                          renderOnMount
                          className="text-center"
                        >
                          <Dropdown.Item
                            as="div"
                            onClick={onDownloadExcelData}
                            className="p-cursor"
                          >
                            Excel <ExcelIcon color="#008000" />
                          </Dropdown.Item>
                          <Dropdown.Item as="div">
                            <a
                              href={`${backendUrl}/api/reports/pdf/sale-by-invoice?${queryString.stringify(
                                rest
                              )}`}
                              target="_blank"
                            >
                              PDF <PDFIcon color="#ff0000" />
                            </a>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </PrivilegedAction> */}

                    {/* <PrivilegedAction
                      eachRole={{
                        name: "Show Summary",
                        parent: "Invoice List",
                      }}
                    >
                      <button
                        onClick={() => setBtnSummaryActive(true)}
                        className="btn summaryBtn "
                      >
                        Show Summary
                      </button>
                    </PrivilegedAction> */}

                    {!showFilter && (
                      <Button
                        variant=""
                        onClick={() => setShowFilter(!showFilter)}
                        className="btn filter ms-2 border rounded-4 d-flex align-items-center px-3 gap-2"
                        style={{
                          height: "40px",
                          lineHeight: "40px",
                        }}
                      >
                        Filter <FilterIcon color="#94A3B8" />
                      </Button>
                    )}
                  </div>
                </header>

                <div className="px-md-4">
                  <Table responsive borderless hover className="text-nowrap product-table">
                    <thead className="bg-white">
                      <tr className=" border-bottom border-top">
                        {/* <th className="py-3">No</th> */}
                        <th className="py-3">Date</th>
                        <th className="py-3">Invoice ID</th>
                        <th className="py-3">Customer name</th>
                        <th className="py-3">Amount</th>
                        <th className="py-3">Date Due</th>
                        <th className="py-3">Status</th>
                        {/* <th className="py-3">Pay Due</th> */}
                        <th className="py-3">Pay Method</th>
                        <th className="py-3">Action</th>
                      </tr>
                    </thead>
                    <tbody className="blue-hover mt-3">
                      {data?.customerLedgers?.map((el, index) => (
                        <tr className=" ">{tableData(el, index)}</tr>
                      ))}
                    </tbody>
                  </Table>

                  {!isFetching && isSuccess && isEmpty(data?.customerLedgers) ? (
                    <NoTableItem queryParams={queryParams} />
                  ) : null}
                </div>

                <div className="d-flex justify-content-between px-3 align-items-center pagination">
                  <div className="pagination_left">
                    <p className="m-0 p-0">Show</p>
                    <select
                      value={queryParams.limit}
                      name="limit"
                      className="form-select "
                      onChange={e => handleSearchQueryChange(e)}
                    >
                      <option value="10">10 rows</option>
                      <option value="20">20 rows</option>
                      <option value="30">30 rows</option>
                      <option value="40">40 rows</option>
                      <option value="50">50 rows</option>
                      <option value="100">100 rows</option>{" "}
                    </select>
                  </div>

                  <ReactPaginate
                    {...paginationOptions}
                    pageCount={Math.ceil(data.count / queryParams.limit)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={0}
                    onPageChange={({ selected }) => {
                      document.body.scrollTop = document.documentElement.scrollTop = 0;
                      setQueryParams({
                        ...queryParams,
                        page: selected + 1,
                      });
                    }}
                    forcePage={queryParams.page - 1}
                  />
                </div>

                <div
                  className={
                    btnSummryActive ? `summaryNew border-top open` : `summaryNew border-top`
                  }
                >
                  <div className="summaryHeader">
                    <h2>Summary</h2>
                    {btnSummryActive && (
                      <button onClick={() => close()} className="btn p-0">
                        <SummaryCloseIcon />
                      </button>
                    )}
                  </div>

                  <div className="gridCont">
                    {/*  <div className="gridChild gridChildBorderLeftBlue">
                  <div className="gridChildLeft">
                    <p className="gridChld1">
                      {currency(data?.totalInvoiceAmt, {
                        symbol: "",
                      }).format()}
                    </p>
                    <p className="gridChld2">Total Invoice Amt (All)</p>
                  </div>

                  <div className="gridChildRight">
                    <SummaryChartIconUp />
                    <p>5%</p>
                  </div>
                </div> */}

                    <div className="gridChild gridChildBorderLeftOrange">
                      <div className="gridChildLeft">
                        <p className="gridChld1">
                          {currency(
                            filterParams.currency === prevailingCurrency || !filterParams.currency
                              ? data?.totalNaira
                              : 0,
                            {
                              symbol: getCurrencySymbol(prevailingCurrency),
                            }
                          ).format()}
                        </p>
                        <p className="gridChld2">
                          Total Invoice in {prevailingCurrency} (
                          {getCurrencySymbol(prevailingCurrency)})
                        </p>
                      </div>

                      <div className="gridChildRight">
                        <SummaryChartIconUp />
                        <p>5%</p>
                      </div>
                    </div>

                    <div className="gridChild gridChildBorderLeftBrown">
                      <div className="gridChildLeft">
                        <p className="gridChld1">
                          {currency(
                            filterParams.currency === "USD" || !filterParams.currency
                              ? data?.totalDollar
                              : 0,
                            {
                              symbol: "$",
                            }
                          ).format()}
                        </p>
                        <p className="gridChld2">Total Invoice in USD ($)</p>
                      </div>

                      <div className="gridChildRight">
                        <SummaryChartIconDown />
                        <p>5%</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ModalLoader show={isfetchingExcel || createInvoicesMutation.isLoading} />
        </main>
      </InvoiceLayout>
    </IsPrivileged>
  );
}

export const CustomInvoiceButton = () => {
  const navigate = useNavigate();

  return (
    <Dropdown>
      <Dropdown.Toggle variant="" bsPrefix="change" className=" border-0">
        <Button className="d-flex text-nowrap align-items-center  me-n2 bg-primary-invoice-btn">
          Create Invoice <ChevronDownIcon />
        </Button>
      </Dropdown.Toggle>
      <Dropdown.Menu
        popperConfig={{
          strategy: "fixed",
        }}
        renderOnMount
        className=" border rounded-4"
      >
        <Dropdown.Item
          as={"button"}
          onClick={() => navigate("/invoices/create-standard-invoice")}
          variant=""
          className=" d-flex gap-1 align-items-center"
        >
          <StandardInvoiceIcon />
          Standard Invoice
        </Dropdown.Item>
        <Dropdown.Item
          as={"button"}
          onClick={() => navigate("/invoices/create-reoccurring-invoice")}
          variant=""
          className=" d-flex gap-1 align-items-center"
        >
          <ReoccurringInvoiceIcon />
          Reoccurring Invoice
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
